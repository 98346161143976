import React from "react";
const ErrorSection = ({ 
  message = "The data you are looking for does not exist.", // Default error message 
  icon = "fa fa-exclamation-circle", // Default icon 
  className = "alert-danger" // Custom CSS class for styling 
}) => {
  return (
    <>
      <section className="error-section sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-mg-8 col-md-10 col-sm-10 col-12 m-auto">
                <div className={`alert ${className}`} role="alert">
                  <i className={icon}></i> &nbsp;  {message}
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorSection;
