import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import axios from "axios";
import SocialLinks from "./SocialLinks";

const Footer = () => {
    const [footerLogoUrl, setFooterLogoUrl] = useState("");

    const BASE_URL = process.env.REACT_APP_APP_URL ;
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`;

    useEffect(() => {
        AOS.init(
            {
                duration: 1200
              }
        ); // Initialize AOS animations
        const fetchFooterLogo = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                if (response.data.photos && response.data.photos.length > 0) {
                    setFooterLogoUrl(response.data.photos[5].url);
                }
            } catch (error) {
                console.error("Error fetching footer logo:", error);
            }
        };

        fetchFooterLogo();
    }, [API_URL_PHOTO]);

    return (
        <footer data-aos="fade-up">
            <div className="container">
                <div className="row g-4">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="footer-wrapper">
                            <Link to="/">
                                <img src={footerLogoUrl || "./logo.png"} alt="footer logo" className="footer-logo" />
                            </Link>
                            <h2 className="sec_heading">Shape Shifting</h2>
                            <ul className="social-icon "> <SocialLinks/></ul>
                           
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="footer-wrapper">
                            <h5 className="footer-heading">Some Useful Links</h5>
                            <ul className="footer-links">
                            

                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/blogs">Blogs</Link></li>
                                <li><Link to="/shop">Shop</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/podcasts">Podcasts</Link></li>
                                <li><Link to="/shape-shifting-mastery">Shape Shifting Mastery</Link></li>
                                <li><Link to="/upcoming-session-info">Upcoming Session</Link></li>
                            
                                </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="footer-wrapper">
                            {/* <h5 className="footer-heading">Have Questions or feedback? Reach out to our support team at</h5> */}
                            {/* <div className="contact-dets">
                                <ul>
                                    <li> <Link to="tel:+91+18977887766"><i className="bi bi-telephone"></i>+18977887766</Link></li>
                                    <li> <Link to="mailto:ShapeShiftingCanada@gmail.com"><i className="bi bi-envelope"></i>ShapeShiftingCanada@gmail.com</Link></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="copyright row">
                    <div className="col-md-6">
                        <p>©2024 | Shape-shifting.ca All Rights Reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <p className="text-start text-md-end">Designed & Developed by <Link to="https://ahatechnocrats.com/" target="_blank" className="fw-bold" rel="noopener noreferrer">AHA Technocrats</Link></p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
