import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import BlogPage from "./pages/BlogPage";
import SuccessStoriesPage from "./pages/SuccessStories";
import ShopPage from "./pages/ShopPage";
import ContactPage from "./pages/ContactPage";

import BlogDetailPage from "./pages/BlogDetailPage";
import ShopDetailPage from "./pages/ShopDetailPage";
import PodcastDetailPage from "./pages/PodcastDetailPage";
import PodcastPage from "./pages/PodcastPage";
// import LoginPage from "./pages/LoginPage";
// import RegisterPage from "./pages/RegisterPage";
import UpcomingSessionInfo from "./pages/UpcomingSessionInfo";
import ServiceDetailPage from './pages/ServiceDetailPage';
import Error404 from './pages/404';
import Welcome from './pages/Welcome';
// import UserProfile from './pages/UserProfile';
import ScrollToTop from './components/ScrollToTop';
import PaymentPublicLinkPage from "./pages/PaymentPublicLinkPage";
// import PaymentStatus from "./pages/PaymentStatusPage";
import Checkout from "./pages/Checkout";
import PaymentDetails from "./pages/PaymentDetails";
import ServiceOne from "./pages/ServiceOne";
import ServiceTwo from "./pages/ServiceTwo";
import ServiceThree from "./pages/ServiceThree";
import ServiceFour from "./pages/ServiceFour";
import ServiceFive from "./pages/ServiceFive";
import Header from "./components/Header";
import Footer from "./components/Footer";
const serviceOnePath = "Tg4Hn7VpD2QwXj9Zs";  // static random string for service-one
const serviceTwoPath = "Zf5Wb8RtKj6Nw3QpE";  // static random string for service-two
const serviceThreePath = "Vx2Tz9YmHk1Lw4JbR";  // static random string for service-three
const serviceFourPath = "Mb7Kp3FwQh8Ln6CvD";  // static random string for service-four
const serviceFivePath = "Jk2Vn5QmWs9Bz4RdY";  // static random string for service-five


const AllRoutes = () => {
    return (
        
        <Router>
            <Header />

          
                <ScrollToTop /> {/* Ensures pages scroll to the top */}
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shape-shifting-mastery" element={<Welcome />} />

                <Route path="/about" element={<AboutPage />} />
                <Route path="/services" element={<ServicePage />} />
                <Route path="/services/:seo_url_slug" element={<ServiceDetailPage />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/blogs/:category/:seo_url_slug" element={<BlogDetailPage />} />
                {/* <Route path="/blogs/:categorySlug?" component={<BlogPage/>} /> */}

                <Route path="/shop" element={<ShopPage />} />
                <Route path="/shop/:categorySlug/:productSlug" element={<ShopDetailPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/podcasts" element={<PodcastPage />} />
                <Route path="/podcasts/:seo_url_slug" element={<PodcastDetailPage />} />
            

                {/* <Route path="/login" element={<LoginPage />} /> */}
                {/* <Route path="/register" element={<RegisterPage />} /> */}
                {/* <Route path="/comment" element={<Comment />} /> */}
                <Route path="/404" element={<Error404 />} />
                <Route path="/welcome" element={<Welcome />} />
                {/* <Route path="/UserProfile" element={<UserProfile />} /> */}
                <Route path="/upcoming-session-info" element={<UpcomingSessionInfo />} />

                 {/* <Route path="/comment" element={<Comment />} /> */}
                 <Route path="/success-stories" element={<SuccessStoriesPage />} />
                 <Route path="/payment/:encryptedKey?" element={<PaymentPublicLinkPage />} />
                 {/* <Route path="/payment-status" element={<PaymentStatus />} /> */}
                 <Route path="/checkout" element={<Checkout />} />
                 <Route path="/payment-details" element={<PaymentDetails />} />

                 <Route path={`/service-one/${serviceOnePath}`} element={<ServiceOne />} />
                <Route path={`/service-two/${serviceTwoPath}`} element={<ServiceTwo />} />
                <Route path={`/service-three/${serviceThreePath}`} element={<ServiceThree />} />
                <Route path={`/service-four/${serviceFourPath}`} element={<ServiceFour />} />
                <Route path={`/service-five/${serviceFivePath}`} element={<ServiceFive />} />

                
                {/* Catch-All Route for 404 */}
                <Route path="*" element={<Error404 />} />
                </Routes>

                <Footer />
            
        </Router>
    );
};

export default AllRoutes;
