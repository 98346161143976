  import React, { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import axiosInstance from "../Auth/axiosInstance";
  import OrderSummary from "../components/OrderSummary";
  import { toast } from "react-toastify";

  const BASE_URL = process.env.REACT_APP_APP_URL;

  const Checkout = () => {
    const [serviceDetails, setServiceDetails] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const encryptedKey = localStorage.getItem("encryptedKey");
    const [isAddressFilled, setIsAddressFilled] = useState(false);
    const API_URL = `${BASE_URL}/api/addresses`;

    const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(false);
    const [loading, setLoading] = useState(true);
    const [billingAddress, setBillingAddress] = useState({
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    });

    const [shippingAddress, setShippingAddress] = useState({
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    });
    const [errors, setErrors] = useState({}); // To hold validation errors


    useEffect(() => {
      const fetchAddresses = async () => {
        try {
          const authToken = localStorage.getItem("authToken");

          if (authToken) {
            // Fetch saved addresses
            const response = await axiosInstance.get(API_URL, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            });

            if (response.data.billing_address) {
              setBillingAddress(response.data.billing_address);
            }
            if (response.data.shipping_address) {
              setShippingAddress(response.data.shipping_address);
            }
          }
        } catch (error) {
          toast.error("Failed to load addresses. Please try again.");
        }
        finally {
          setLoading(false);
        }
      };

      fetchAddresses();
    }, []); // Fetch addresses on component mount

    const handleBillingChange = (e) => {
      const { id, value } = e.target;
      const field = id.replace("billing_", "");
      setBillingAddress((prev) => ({ ...prev, [field]: value }));
      if (isShippingSameAsBilling) {
        setShippingAddress((prev) => ({ ...prev, [field]: value }));
      }
      setErrors((prev) => ({ ...prev, [`billing_address.${field}`]: "" })); // Clear error for field
    };

    const handleShippingChange = (e) => {
      const { id, value } = e.target;
      const field = id.replace("shipping_", "");
      setShippingAddress((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => ({ ...prev, [`shipping_address.${field}`]: "" })); // Clear error for field
    };

    const handleCheckboxChange = () => {
      setIsShippingSameAsBilling((prev) => !prev);
      if (!isShippingSameAsBilling) {
        setShippingAddress({ ...billingAddress });
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      const authToken = localStorage.getItem("authToken");

      if (!authToken) {
        toast.error("You need to be logged in to save addresses.");
        return;
      }
      const requiredFields = [
        "name",
        "street",
        "city",
        "state",
        "zip",
        "country",
      ];

      const allFieldsFilled = requiredFields.every(
        (field) => billingAddress[field] && shippingAddress[field]
      );

      if (!allFieldsFilled) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const data = {
        billing_address: billingAddress,
        shipping_address: isShippingSameAsBilling ? billingAddress : shippingAddress,
      };

      try {
        await axiosInstance.post(API_URL, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        toast.success("Address saved successfully!");
        setIsAddressFilled(true); 
      } catch (error) {
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors); // Set validation errors
        } else {
          toast.error("Failed to save address. Please try again.");
        }
      }
    };
  

    const renderInput = (id, label, value, onChange, error, disabled = false) => (
      <div className="col-sm-6 mb-3">
        <label htmlFor={id} className="form-label">
          {label}
          <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${error ? "is-invalid" : ""}`}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
    // Fetch user details
    const fetchUserDetails = async () => {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        try {
          const response = await axiosInstance.get("api/userdetails", {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          setUserDetails(response.data); // Assuming the API response includes user details like name, email, phone number
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    // Fetch addresses
    // Fetch service details
    useEffect(() => {
      const API_URL = encryptedKey
        ? `${BASE_URL}/api/payment/${encryptedKey}`
        : `${BASE_URL}/api/payment`;

      const fetchServiceDetails = async () => {
        try {
          const response = await fetch(API_URL);
          const data = await response.json();
          setServiceDetails(data);
        } catch (error) {
          console.error("Error fetching service details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchServiceDetails();
      fetchUserDetails(); // Fetch user details when component mounts
      // Fetch addresses when component mounts
    }, [encryptedKey]);
    // Handle updating user details (PUT method)
    const handleUpdateUserDetails = async (updatedDetails) => {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        try {
          const response = await fetch(`${BASE_URL}/api/update-user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(updatedDetails),
          });
          const data = await response.json();
          setUserDetails(data); // Update user details in the state
        } catch (error) {
          console.error("Error updating user details:", error);
        }
      }
    };
    const renderActionButton = () => {
      return isAddressFilled ? (
        <Link
          to="/payment-details"
          className="btn theme_btn"
        >
          Continue
        </Link>
      ) : (
        <button
          className="btn theme_btn"
          onClick={handleSubmit}
        >
          Save Address
        </button>
      );
    };


  if (loading) {
    return (
      <>
        <div className="checkout-main sec_padding">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-8">
              <div className="progress-container mb-5">
                <div className="progress" id="progress"></div>
                <div className="circle current_tab number_1 active">
                  <div className="caption">Details</div>
                </div>
                <div className="circle number_2">
                  <div className="caption">Payment</div>
                </div>
                <div className="circle number_3">
                  <div className="caption">Completion</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row skeleton-container skeleton-address">
            <div className="col-md-8">
              <div className="card">
              <h5 className="card-header bg-white">
                <div className="heading skeleton"></div>
              </h5>
                <div className="card-body">
                  <form id="billingAddressForm">
                    <div className="row">
                      <div className="col-sm- col-md-4 mb-3">
                      <label className="form-label skeleton"></label>
                      <div className="input skeleton"></div>
                      </div>
                      <div className="col-sm-6 col-md-4 mb-3">
                      <label className="form-label skeleton"></label>
                      <div className="input skeleton"></div>
                      </div>
                      <div className="col-md-4 col-sm-6 mb-3">
                      <label className="form-label skeleton"></label>
                      <div className="input skeleton"></div>
                      </div>
                    </div>
                    <div className="row">
                    
                      <div className="col-sm-12 px-0 my-3">
                         
                          <h5 className="card-header bg-white">
                            <div className="heading skeleton"></div>
                          </h5>
                            <div className="card-body">
                              <form id="billingAddressForm">
                                <div className="row">
                                  <div className="col-sm-6 mb-3">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                  </div>
                                  <div className="col-sm-6 mb-3">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6 mb-3">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                  </div>
                                  <div className="col-sm-6 mb-3">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6 mb-3">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                  </div>
                                  <div className="col-sm-6 mb-3">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          <div>
                            <div className="form-check position-relative ms-2 my-3">
                              <div className="input-checkbox skeleton"></div>
                              <label className="form-check-label skeleton"></label>
                            </div>
                          </div>

                         
                          <h5 className="card-header bg-white">
                <div className="heading skeleton"></div>
              </h5>
                            <div className="card-body">
                            <form id="billingAddressForm">
                              <div className="row">
                                <div className="col-sm-6 mb-3">
                                  <label className="form-label skeleton"></label>
                                  <div className="input skeleton"></div>
                                </div>
                                <div className="col-sm-6 mb-3">
                                  <label className="form-label skeleton"></label>
                                  <div className="input skeleton"></div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6 mb-3">
                                  <label className="form-label skeleton"></label>
                                  <div className="input skeleton"></div>
                                </div>
                                <div className="col-sm-6 mb-3">
                                  <label className="form-label skeleton"></label>
                                  <div className="input skeleton"></div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6 mb-3">
                                  <label className="form-label skeleton"></label>
                                  <div className="input skeleton"></div>
                                </div>
                                <div className="col-sm-6 mb-3">
                                  <label className="form-label skeleton"></label>
                                  <div className="input skeleton"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="text-end">
                          <div className="btn skeleton"></div>
                          </div>
                        </div>
                      </div>
                  </form>
                
                </div>
              </div>

              {/* <AddressForm onAddressFilled={handleAddressCompletion}/> */}

            </div>


            <OrderSummary />
          </div>
        </div>
      </div>
      </>
    );
  }


    return (
      <>

        <div className="checkout-main sec_padding">
          <div className="container">
            <div className="row mb-2">
              <div className="col-md-8">
                <div className="progress-container mb-5">
                  <div className="progress" id="progress"></div>
                  <div className="circle current_tab number_1 active">
                    <div className="caption">Details</div>
                  </div>
                  <div className="circle number_2">
                    <div className="caption">Payment</div>
                  </div>
                  <div className="circle number_3">
                    <div className="caption">Completion</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <h5 className="card-header">User Details</h5>
                  <div className="card-body">
                    <form id="billingAddressForm">
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label htmlFor="billing_name" className="form-label">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="billing_name"
                            placeholder="Enter your full name"
                            value={userDetails?.data?.name || ""}
                            readOnly
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter your email"
                            value={userDetails?.data?.email || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label htmlFor="billing_phone" className="form-label">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="billing_phone"
                            placeholder="Enter your phone number"
                            value={userDetails?.data?.phone || ""}
                            onChange={(e) =>
                              handleUpdateUserDetails({ phone: e.target.value })
                            }
                          />
                        </div>
                      
                        <div className="col-sm-12 mb-3">
                          
                              <h5 className="card-header">Billing Address</h5>
                              <div className="card-body">
                                <form id="billingAddressForm">
                                  <div className="row">
                                    {renderInput(
                                      "billing_name",
                                      "Full Name",
                                      billingAddress.name,
                                      handleBillingChange,
                                      errors["billing_address.name"]
                                    )}
                                    {renderInput(
                                      "billing_street",
                                      "Street Address",
                                      billingAddress.street,
                                      handleBillingChange,
                                      errors["billing_address.street"]
                                    )}
                                  </div>
                                  <div className="row">
                                    {renderInput(
                                      "billing_city",
                                      "City",
                                      billingAddress.city,
                                      handleBillingChange,
                                      errors["billing_address.city"]
                                    )}
                                    {renderInput(
                                      "billing_state",
                                      "State",
                                      billingAddress.state,
                                      handleBillingChange,
                                      errors["billing_address.state"]
                                    )}
                                  </div>
                                  <div className="row">
                                    {renderInput(
                                      "billing_zip",
                                      "Zip Code",
                                      billingAddress.zip,
                                      handleBillingChange,
                                      errors["billing_address.zip"]
                                    )}
                                    {renderInput(
                                      "billing_country",
                                      "Country",
                                      billingAddress.country,
                                      handleBillingChange,
                                      errors["billing_address.country"]
                                    )}
                                  </div>
                                </form>
                              </div>
                          

                          <div className="form-check ms-2 my-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="sameAsBilling"
                              checked={isShippingSameAsBilling}
                              onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label" htmlFor="sameAsBilling">
                              Shipping address is the same as billing address
                            </label>
                          </div>

                          
                              <h5 className="card-header">Shipping Address</h5>
                              <div className="card-body">
                                <form id="shippingAddressForm">
                                  <div className="row">
                                    {renderInput(
                                      "shipping_name",
                                      "Full Name",
                                      shippingAddress.name,
                                      handleShippingChange,
                                      errors["shipping_address.name"],
                                      isShippingSameAsBilling
                                    )}
                                    {renderInput(
                                      "shipping_street",
                                      "Street Address",
                                      shippingAddress.street,
                                      handleShippingChange,
                                      errors["shipping_address.street"],
                                      isShippingSameAsBilling
                                    )}
                                  </div>
                                  <div className="row">
                                    {renderInput(
                                      "shipping_city",
                                      "City",
                                      shippingAddress.city,
                                      handleShippingChange,
                                      errors["shipping_address.city"],
                                      isShippingSameAsBilling
                                    )}
                                    {renderInput(
                                      "shipping_state",
                                      "State",
                                      shippingAddress.state,
                                      handleShippingChange,
                                      errors["shipping_address.state"],
                                      isShippingSameAsBilling
                                    )}
                                  </div>
                                  <div className="row">
                                    {renderInput(
                                      "shipping_zip",
                                      "Zip Code",
                                      shippingAddress.zip,
                                      handleShippingChange,
                                      errors["shipping_address.zip"],
                                      isShippingSameAsBilling
                                    )}
                                    {renderInput(
                                      "shipping_country",
                                      "Country",
                                      shippingAddress.country,
                                      handleShippingChange,
                                      errors["shipping_address.country"],
                                      isShippingSameAsBilling
                                    )}
                                  </div>
                                </form>
                              </div>
                          

                            <div className="text-end">
  {renderActionButton()}

                            </div>
                          </div>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
              <OrderSummary />
            </div>
          </div>
        </div>

      </>
    );
  };
  export default Checkout;
