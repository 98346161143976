import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom"; 
import axios from "axios";
import AOS from 'aos';
import Breadcrumb from "./Breadcrumb";
// import axiosInstance from "../Auth/axiosInstance";
// import { toast } from "react-toastify";
// import { useProfile } from "../Auth/profileContext"; // Import the custom hook

const Header = () => {
    // const { profileImage, updateProfileImage } = useProfile(); // Use Profile Context
    const [logoUrl, setLogoUrl] = useState("");
    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`;
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.slice(1)); // Remove the # from the hash
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);



    const isDropdownActive =
        location.pathname === '/shape-shifting-mastery' ||
        location.pathname === '/upcoming-session-info';

    const isStoriesDropdownActive =
        location.pathname === '/success-stories'
        

    useEffect(() => {
        AOS.init({ duration: 100 });
        const fetchLogo = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                if (response.data.photos && response.data.photos.length > 0) {
                    setLogoUrl(response.data.photos[5].url);
                }
            } catch (error) {
                console.error("Error fetching logo:", error);
            }
        };
        fetchLogo();
    }, [API_URL_PHOTO]);

    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // const handleLogout = async () => {
    //     try {
    //         const token = localStorage.getItem("authToken");
    //         const response = await axiosInstance.get("api/logout", {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });

    //         if (response.status === 200) {
    //             localStorage.removeItem("authToken");
    //             toast.success("Logout Successful!"); // Error toast
    //             navigate("/login");
    //         }
    //     } catch (error) {
    //         console.error("Error logging out:", error);
    //     }
    // };

    // useEffect(() => {
    //     const fetchUserProfile = async () => {
    //         const token = localStorage.getItem("authToken");
    //         if (token) {
    //             try {
    //                 const response = await axiosInstance.get("api/userdetails", {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 });
    //                 if (response.status === 200 && response.data.data) {
    //                     const userPhoto = response.data.data.photo;
    //                     updateProfileImage(`${BASE_URL}${userPhoto || "/assets/images/Michele.png"}`);
    //                 }
    //             } catch (error) {
    //                 console.error("Error fetching user details:", error);
    //                 navigate("/login");
    //             }
    //         }
    //     };
    //     fetchUserProfile();
    // }, [navigate, updateProfileImage]); // Add updateProfileImage to dependencies

    // const isLoggedIn = localStorage.getItem("authToken");

    return (
        <>
            <header id="header" data-aos="fade-down" className={`header-main ${scrolled ? "bg-header" : ""}`}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-xl">
                        <NavLink className="logo" to="/">
                            <img src={logoUrl || "../../logo.png"} alt="logo" />
                        </NavLink>

                        <div className="d-flex align-items-center">
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav"
                                aria-controls="navbarNav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                                <ul className="navbar-nav align-items-lg-center">
                                    <li className="nav-item">
                                        <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        {/* <i class="fa-solid fa-house"></i> */}
                                        Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/about" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            About
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/services" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            Services
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/shop" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            Shop
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/podcasts" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            Podcasts
                                        </NavLink>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <NavLink
                                            to="/Shape-Shifting-Mastery"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            className={isDropdownActive ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"}
                                        >
                                            Upcoming Session
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li><NavLink className="dropdown-item" to="/shape-shifting-mastery">Zazen</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/upcoming-session-info">Upcoming Online Session</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <NavLink
                                            to="/success-stories"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            className={isStoriesDropdownActive ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"}
                                        >
                                            Success Stories
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li><NavLink className="dropdown-item" to="/success-stories">Success Stories</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/success-stories#bride-success-stories">Bride Success Stories</NavLink></li>
                                        </ul>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to="" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            Success Stories
                                        </NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to="/blogs" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            Blogs
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/contact" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            Contact Us
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            {/* <ul className="user-profile-dropdown">
                                {!isLoggedIn ? (
                                    <li className="nav-item">
                                        <Link to="/login" className="btn theme_btn ms-0 ms-lg-4">Login / Register</Link>
                                    </li>
                                ) : (
                                    <li className="nav-item dropdown">
                                        <NavLink
                                            to="/UserProfile"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            className={isDropdownActive ? "nav-link dropdown-toggle active" : "nav-link dropdown-toggle"}
                                        >
                                            <span className="user-profile-icon" title="User">
                                                <img src={profileImage} alt="User" />
                                            </span>
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li><NavLink className="dropdown-item" to="/UserProfile">Profile</NavLink></li>
                                            <li><NavLink onClick={handleLogout} className="dropdown-item" to="/login">Log Out</NavLink></li>
                                        </ul>
                                    </li>
                                )}
                            </ul> */}
                        </div>
                    </div>
                </nav>
            </header>

            <Breadcrumb />
        </>
    );
};

export default Header;
