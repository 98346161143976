  import React, { useState, useEffect } from "react";
  import { Link ,useLocation } from "react-router-dom";
  import AOS from "aos";
  import axios from "axios";

  const Services = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const servicesPerPage = 4; // Display 4 services per page
    const BASE_URL =  process.env.REACT_APP_APP_URL;
    const location = useLocation(); // Get current location
    const isHomePage = location.pathname === "/"; // Check if the path is the home page

    // Dynamically set API URL based on the current page
    const API_URL = `${BASE_URL}/api/v1/topics/2/page/1/count/20`;

    useEffect(() => {
      AOS.init({ duration: 1200 }); 

      const fetchServices = async () => {
        setLoading(true); // Ensure loading state is true before fetching
        try {
          const response = await axios.get(API_URL);
          if (response && response.data && response.data.topics) {
            let filteredServices = response.data.topics;

            // Only apply filtering on page 1
            if (currentPage === 1) {
              filteredServices = filteredServices.slice(2,6); // Filter out the first 2 services
            }

            // Extract subtitle and clean details for each service
            filteredServices = filteredServices.map((service) => {
              const { subtitle, cleanDetails } = extractSubtitleAndClean(service.details);
              return {
                ...service,
                subtitle,
                details: cleanDetails,
              };
            });

            setServices(filteredServices);
          } else {
            throw new Error("No topics found in the response");
          }
        } catch (err) {
          setError("Error fetching data");
          console.error(err);
        } finally {
          setLoading(false); // Set loading to false once data is fetched or error occurs
        }
      };

      fetchServices();
    }, [API_URL, currentPage]); // Add API_URL and currentPage to the dependencies to fetch data on change

    const extractSubtitleAndClean = (details) => {
      const h4Regex = /<h4[^>]*>(.*?)<\/h4>/;
      const match = details.match(h4Regex);
      const subtitle = match ? match[1] : "No subtitle available";
      const cleanDetails = details.replace(h4Regex, "").trim();
      return { subtitle, cleanDetails };
    };

    

    const formatTitle = (title) => {
      const words = title.split(" ");
      if (words.length === 2) words[1] = `<span class="stylish">${words[1]}</span>`;
      if (words.length === 3) words[2] = `<span class="stylish">${words[2]}</span>`;
      if (words.length === 4) {
        words[2] = `<span class="stylish">${words[2]}</span>`;
        words[3] = `<span class="stylish">${words[3]}</span>`;
      }
      return words.join(" ");
    };

    // For pagination, calculate which services to display on the current page
    const indexOfLastService = currentPage * servicesPerPage;
    const indexOfFirstService = indexOfLastService - servicesPerPage;
    const currentService = services.slice(indexOfFirstService, indexOfLastService);

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(services.length / servicesPerPage);

    if (loading) {
      return (
        
          <section className="inner-banner-section skeleton-container">
            <div className="container">
              <div className="row">
                <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                  <div className="banner-caption">
                    <div>
                      <h5 className="text-banner-badge hero skeleton"> </h5>
                      <h1 className="main-heading hero mb-0 skeleton"> </h1>
                    </div>
                    <p className="pera hero skeleton"></p>
                    <div className="d-flex gap-3 justify-content-between">
                      <div className="btn-skeleton hero skeleton"></div>
                      <div className="hero-btn-container d-flex gap-1">
                        <span className="play-skeleton hero skeleton"></span>
                        <p className="skeleton hero btn-skeleton"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                  <div className="banner-img">
                    <div className="video hero skeleton"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
       
      );
    }

    if (error) return <p>{error}</p>;

    // Gradient classes for styling
    const gradientClasses = ["gradient-one", "gradient-two", "gradient-three", "gradient-four"];

    return (
      <>
        <section className="sec_padding our-services">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-4 mb-3" data-aos="fade-right">
                <h6 className="sub_heading text-center">Services</h6>
                <h2 className="sec_heading-stylish text-center">Our Offerings</h2>
                <span className="down-arrow">
                  <i className="fa-solid fa-arrow-down-long"></i>
                </span>
              </div>

              {/* Loop through services and render each one */}
              {currentService.map((service, index) => (
                <div key={service.id} className="col-md-6" data-aos="fade-up">
                  <div className="padding-top-item">
                    <div className="service-item text-center">
                      <div className={`box-details ${gradientClasses[index]}`}>
                        <h5 className="Presents">
                          <span className="stylish">Michele </span>Presents
                        </h5>
                        <h3
                          className="title"
                          dangerouslySetInnerHTML={{ __html: formatTitle(service.title) }}
                        ></h3>
                        <h4 className="sub-title">{service.subtitle}</h4>
                      </div>
                      {/* Display the cleaned details with HTML */}
                      <div className="pera" dangerouslySetInnerHTML={{ __html: service.details }}></div>
                      <Link to={`/services/${service.seo_url_slug}`} className="btn theme_btn">
    Discover More
  </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Pagination */}
        {!isHomePage && totalPages > 1 && (
        <div className="pagination">
          <Link
            to="#"
            onClick={() => handlePageChange(currentPage - 1)}
            className={`fa-solid icon pre ${currentPage === 1 ? "disabled" : ""}`}
            aria-disabled={currentPage === 1 ? "true" : "false"} // Adds accessibility
          >
            <i className={`fa-arrow-left ${currentPage === 1 ? "disabled-arrow" : ""}`}></i>
          </Link>

          <ul className="pagination-items">
            {Array.from({ length: totalPages }, (_, i) => (
              <Link to="#" key={i} onClick={() => handlePageChange(i + 1)}>
                <li className={`item ${currentPage === i + 1 ? "active" : ""}`}>
                  {i + 1}
                </li>
              </Link>
            ))}
          </ul>

          <Link
            to="#"
            onClick={() => handlePageChange(currentPage + 1)}
            className={`fa-solid icon next ${currentPage === totalPages ? "disabled" : ""}`}
            aria-disabled={currentPage === totalPages ? "true" : "false"} // Adds accessibility
          >
            <i className={`fa-arrow-right ${currentPage === totalPages ? "disabled-arrow" : ""}`}></i>
          </Link>
        </div>
      )}
      </>
    );
  };

  export default Services;
