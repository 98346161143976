import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const PaymentPublic = () => {
  const { encryptedKey } = useParams();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL; // Laravel backend URL

  const navigate = useNavigate();

  const API_URL = encryptedKey
    ? `${BASE_URL}/api/payment/${encryptedKey}` // If encryptedKey exists
    : `${BASE_URL}/api/payment`; 

  useEffect(() => {
    const fetchServiceDetail = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        setService(response.data);
        console.log(response.data);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetail();
  }, [encryptedKey, API_URL]);
  localStorage.setItem("encryptedKey", encryptedKey);
  // const handleCheckoutClick = () => {
  //   const authToken = localStorage.getItem("authToken");
  //   if (!authToken) {
  //     localStorage.setItem("redirectUrl", "/checkout");
  //     navigate("/login"); // Redirect to login page
  //   } else {
  //     navigate("/checkout"); // If logged in, proceed to checkout page
  //   }
  // };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <Helmet>
        <title>{service ? service.service_name : "Loading..."} - Shape Shifting Mastery</title>
        <meta
          name="description"
          content={service ? `Read more about ${service.service_name}.` : "Loading service details."}
        />
      </Helmet>

      <section className="service-detail sec_padding gradient-one">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="box-service-details pt-0 pt-md-4 mt-0">
                <h5 className="Presents">
                  <span className="stylish">Michele </span>Presents
                </h5>
                <h3 className="sec_heading text-center">{service.service_name}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="service-detail-img">

              <img src="https://admin.shapeshiftingmastery.ca/uploads/topics/17326895457941.jpg" alt="60 day Shape Shifting"/>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6" data-aos="zoom-in-left">
              <h2 className="sec_heading pt-2 pt-md-0">{service.service_name}</h2>
              <p className="pera" dangerouslySetInnerHTML={{ __html: service.service_description || "No description right now" }} />
              {/* <ul className="list-with-check">
                <ul>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="pera">Personalized Diet Plan</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="pera">Weekly Workout Sessions</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="pera">1-on-1 Fitness Coaching</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="pera">Monthly Health Assessments</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="pera">Access to Health Tracking App</span>
                  </li>
                </ul>
              </ul> */}
              <h3 className="price">Price : {service.price}$</h3>
              <span className="pera">Date of Expiry: {service.dateofexpiry}</span>
              <Link to="/" className="btn theme_btn mt-3" >
                Checkout
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentPublic;
