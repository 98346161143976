import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import axiosInstance from "../Auth/axiosInstance";
import { Helmet } from "react-helmet";

const UpcomingSessionInfo = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [errors, setFormErrors] = useState(null);
  const [register, setRegister] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    referred: "",
  });

  const BASE_URL = process.env.REACT_APP_APP_URL;
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/topic/216`
        );
        setData(response.data.topic[0]);
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, [BASE_URL]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = "First Name is required";
    if (!formData.lastName) errors.lastName = "Last Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.mobile) errors.mobile = "Mobile Number is required";
    if (!formData.referred) errors.referred = "Referral is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // If no errors, form is valid
  };

  

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
  
    try {
      await axiosInstance.post('/api/send-session-Mail', {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        mobile: formData.mobile,
        referred: formData.referred,
      });
      setRegister(true);
      alert("Registration successful! Please login.");
      
    } catch (err) {
      console.error("Registration  error:", err.response?.data?.message || err.message);
      setError(err.response?.data?.message || "Registration failed");
    }
  };

  if (loading) {
    return (
     <>
       {/* Shape-Shifting-Mastery skeleton*/}
       <section className="welcome-top sec_padding skeleton-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="box-welcome-top pt-0 pt-md-4 mt-0"
                data-aos="zoom-in"
              >
                <div className="Presents skeleton m-auto"></div>
                <div className="title skeleton m-auto mt-2 mt-md-3 mb-2 mb-md-4"></div>
        
                  <div className="sub_heading skeleton m-auto"></div>

                <div className="hosted-container">

                  <div className="hosted-content d-flex flex-wrap gap-2">
                    <p className="pera skeleton m-auto"></p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }


  const detailOne = data.fields.find((field) => field.title === "Detail one")?.value;
  const imageOne = data.fields.find((field) => field.title === "Image one")?.value;
  const detailtwo = data.fields.find((field) => field.title === "Detail two")?.value;
  const SessionInfo = data.fields.find((field) => field.title === "Session Info")?.value;
  const imageTwo = data.fields.find((field) => field.title === "Image two")?.value;
  const listField = data.fields.find((field) => field.title === "List");
  const listItems = listField?.value.split("\r\n") || [];
  const detailthree = data.fields.find((field) => field.title === "Detail three")?.value || "";
  const detailThrees = detailthree.split("\r\n");
  const regards = data.fields.find((field) => field.title === "Regards")?.value || "";
  const regardsLines = regards.split("\r\n");
  const detailFour = data.fields.find((field) => field.title === "Detail four")?.value || "";
  const detailFourLines = detailFour.split("\r\n");
  







  return (
    <>
      <Helmet>
  <title>Upcoming Session Info  - Shape Shifting Mastery </title> 
  <meta name="description" content={`Upcoming Session Info`} />
</Helmet>

      {/* Top Section */}
      <section className="welcome-top sec_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="box-welcome-top pt-0 pt-md-4 mt-0"
                data-aos="zoom-in"
              >
                <h5 className="Presents text-center">
                  <span className="stylish">Michele </span>Presents
                </h5>
                <h3 className="title text-center">
                  {data.title.split(" ").map((word, index) => (
                    <span
                      key={index}
                      className={
                        word.toLowerCase() === "metamorphosis" ? "stylish" : ""
                      }
                    >
                      {word}{" "}
                    </span>
                  ))}
                </h3>
                {data.fields.find((field) => field.title === "Sub heading") && (
                  <h4 className="sub_heading text-center">
                    {
                      data.fields.find((field) => field.title === "Sub heading")
                        .value
                    }
                  </h4>
                )}

                <div className="hosted-container">

                  <div className="d-flex logo-container flex-column flex-wrap gap-1">

                    <h2 className="Presents text-center mb-1 mb-md-3">Sign up to get alerted of upcoming information sessions – live and/or online </h2>

                  

                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----Unique Program Section---- */}
      <section className="sec_padding unique-program pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="unique-program-content">
                <p className="pera text-center" data-aos="fade-up">
                  {detailOne}
                </p>
                <button
                  className="btn theme_btn"
                  data-aos="fade-up"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                >
                  Register Now
                </button>

                <div className="img-unique-program" data-aos="fade-up">

                  <img src={imageOne ? `${BASE_URL}/uploads/topics/${imageOne}` : ""} alt="Mission-Slimpossible" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------------Information Session--------------  */}

      <section className="sec_padding session-information">
        <div className="container">
          <div className="row py-3 py-md-5">
            <div className="col-12" data-aos="fade-up">
              <p className="pera text-center">
                {detailtwo}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center" data-aos="fade-up">
                <div className="session-information-box">

                  <h2 className="sec_heading text-center">{SessionInfo}</h2>
                  <div className="row">
                    <div className="col-md-6">

                      <ul className="list-with-check pt-2">
                        {listItems.map((item, index) => (
                          <li key={index}>
                            <i className="fa fa-check"></i>
                            <span className="pera">{item}</span>
                          </li>
                        ))}
                      </ul>


                      <div>
                        {detailThrees.map((item, index) => (
                          <p key={index} className="pera">
                            {item}
                          </p>
                        ))}
                      </div>
                      <div className="about-box">
                        {regardsLines.map((line, index) => (
                          <p key={index} className="pera fw-bold">
                            {line}
                          </p>
                        ))}
                      </div>


                    </div>
                    <div className="col-md-6">
                      <div className="img-welcome-ready" data-aos="zoom-in-right">
                      {/*  eslint-disable-next-line */}
                        <img src={imageTwo ? `${BASE_URL}/uploads/topics/${imageTwo}` : ""} alt="Mission-Slimpossible-2 Image Not Found" loading="lazy" />

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

      {/* -----section-bottom-about------  */}

      <section className="sec_padding bottom-section-welcome">
        <div className="container">
          <div className="row">
           
            <div className="col-xl-12">

            <div className="content-bottom-about" data-aos="fade-up">
    {detailFourLines.map((line, index) => {
      // Render the mission statement
      if (line.includes("Mission Slimpossible")) {
        return (
          <p key={index} className="sub_heading text-center">
            {line}
          </p>
        );
      }
      // Render the line with the name
      else if (line.includes("Here's to a life full of kindnesses and laughter")) {
        return (
          <p key={index} className="pera fw-bold">
            {line}
          </p>
        );
      }
      // Render the name (Michele)
      else if (line === "Michele") {
        return (
          <p key={index} className="pera fw-bold">
            {line}
          </p>
        );
      }
      // Render the website link
      else if (line === "www.masterhabits.com") {
        return (
          <Link key={index} href="https://www.masterhabits.com" target="_blank" rel="noopener noreferrer">
            {line}
          </Link>
        );
      }
      // Render the phone number
      else if (line === "1-778-668-(LOVE)5683") {
        return (
          <p key={index} className="pera">
            {line}
          </p>
        );
      }
      // Render any other lines as regular text
      else {
        return (
          <p key={index} className="pera">
            {line}
          </p>
        );
      }
    })}

    <button
                  className="btn theme_btn"
                  data-aos="fade-up"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                >
                  Register Now
                </button>
  </div>

            </div>
          </div>
        </div>
      </section>


      <div
            className="modal fade"
            id="registerModal"
            tabIndex="-1"
            aria-labelledby="registerModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <h2 className="sec_heading-stylish  mb-0">Register Now!</h2>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div className="modal-body">
                {/* <div className="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Registration Successfull!</strong>.
                </div> */}
                <form className="welcome-register-form contact-form pt-0" onSubmit={handleRegister}>
                  <div className="fields">
                    <div className="input-field">
                      <input
                        type="text"
                        className="theme_input form-control mb-2"
                        name="firstName" // Match state key
                        placeholder="First Name*"
                        value={formData.firstName} // Controlled input
                        onChange={handleInputChange} // Handle input changes
                      />
                      {errors?.firstName && <div className="error-message">{errors.firstName}</div>}
                    </div>

                    <div className="input-field">
                      <input
                        type="text"
                        className="theme_input form-control mb-2"
                        name="lastName" // Match state key
                        placeholder="Last Name*"
                        value={formData.lastName} // Controlled input
                        onChange={handleInputChange} // Handle input changes
                      />
                      {errors?.lastName && <div className="error-message">{errors.lastName}</div>}
                    </div>

                    <div className="input-field">
                      <input
                        type="tel"
                        className="theme_input form-control mb-2"
                        name="mobile" // Match state key
                        placeholder="Your Number*"
                        value={formData.mobile} // Controlled input
                        onChange={handleInputChange} // Handle input changes
                      />
                      {errors?.mobile && <div className="error-message">{errors.mobile}</div>}
                    </div>

                    <div className="input-field">
                      <input
                        type="email"
                        className="theme_input form-control mb-2"
                        name="email" // Match state key
                        placeholder="Your Email*"
                        value={formData.email} // Controlled input
                        onChange={handleInputChange} // Handle input changes
                      />
                      {errors?.email && <div className="error-message">{errors.email}</div>}
                    </div>

                    <div className="input-field">
                      <input
                        type="text"
                        className="theme_input form-control mb-2"
                        name="referred" // Match state key
                        placeholder="Who referred you"
                        value={formData.referred} // Controlled input
                        onChange={handleInputChange} // Handle input changes
                      />
                      {errors?.referred && <div className="error-message">{errors.referred}</div>}
                    </div>

                    <input
                      type="submit"
                      className="btn theme_btn w-100"
                      value="Register Now"
                    />
                  </div>

                  {errors && Object.keys(errors).length > 0 && (
                    <div className="alert alert-danger mt-2" role="alert">
                      Please correct the errors above.
                    </div>
                  )}
                
                  {register && !errors && (
                  <div className="alert alert-success mt-2" role="alert">
                    You have successfully registered!
                  </div>
                )}
                </form>
                </div>
            </div>
            </div>
        </div>  
     
    
    </>
  );
};

export default UpcomingSessionInfo;