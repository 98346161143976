import React from "react";
import Banner from "./Banner";
import About from "./About";
import Philosophy from "./Philosophy";
import Services from "./Services";
import Transformation from "./Transformation";
import Testimonials from "./Testimonials";
import WhyChooseUs from "./WhyChooseUs";
import Blogs from "./Blogs";
import { LogoProvider } from "../contexts/LogoContext";
import { Helmet } from "react-helmet";

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Home - Shape Shifting Mastery</title> {/* Replace "Your Blog Name" with your site's name */}
                <meta name="description" content={`Contact Us .`} />
                <meta name="keywords" content={'home , shape-shifting , body , transformation, metamorphosis'} />
            </Helmet>
            <LogoProvider>
                <Banner />
                <About />
                <Philosophy />
                <Services />
                <Transformation />
                <Testimonials />
                <WhyChooseUs />
                <Blogs />
            </LogoProvider>
        </>
    );
};

export default Home;
