import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link } from "react-router-dom";
import OrderSummary from "../components/OrderSummary";

const stripePromise = loadStripe('pk_test_51PHitV2NQ7fOhHDNoGLh96krNYGRQhfmpkjPoUJrFSVN2KhkQ0ypNEP2MvHkU4MaiVybddnuDw7iuWj1vlZ6jAsw00xSVXbnHD'); // Your Stripe publishable key

const BASE_URL = process.env.REACT_APP_APP_URL; // Laravel backend URL

const PaymentDetails = () => {
    const navigate = useNavigate(); // To navigate after payment is processed
    const [serviceDetails, setServiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const encryptedKey = localStorage.getItem('encryptedKey');
    const authToken = localStorage.getItem('authToken'); // Retrieve the auth token from localStorage

    // Fetch service details
    useEffect(() => {
        const API_URL = encryptedKey
            ? `${BASE_URL}/api/payment/${encryptedKey}`
            : `${BASE_URL}/api/payment`;

        const fetchServiceDetails = async () => {
            try {
                const response = await fetch(API_URL, {
                    headers: {
                        'Authorization': ` ${authToken}`,
                    },
                });
                const data = await response.json();
                setServiceDetails(data);
            } catch (error) {
                console.error("Error fetching service details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceDetails();
    }, [encryptedKey, authToken]);

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
   
        if (!stripe || !elements) {
            return;
        }
        const cardholderName = document.getElementById('cardholderName').value;
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
            name: cardholderName, // Pass cardholder name
        },
        });

        if (error) {
            console.error(error);
            alert("Payment failed. Please try again.");
            return;
        }
        const paymentPayload = {
            amount: serviceDetails.price * 100, // Convert price to cents
            payment_method: paymentMethod.id,
            cardholderName: cardholderName, // Include cardholder name
            service_name: serviceDetails.service_name,
            service_description: serviceDetails.service_description,

        };
        // const authToken = localStorage.getItem("authToken");
        const paymentIntentResponse = await fetch(`${BASE_URL}/api/process-payment`, {
            method: 'POST',
            headers: {
                Authorization:  `Bearer ${authToken}`,
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify(paymentPayload),
            
        });
        console.log("Payment intent:", paymentIntentResponse);
        const paymentIntent = await paymentIntentResponse.json();
        
        // const paymentIntent = await paymentIntentResponse.json();
        const { error: confirmError, paymentIntent: confirmedPaymentIntent } =
            await stripe.confirmCardPayment(paymentIntent.client_secret);

        if (confirmError) {
            console.error(confirmError);
            alert("Payment confirmation failed.");
            return;
        }

        // Redirect to payment status page with the result
        if (confirmedPaymentIntent.status === 'succeeded') {
            navigate('/payment-status', { state: { status: 'success' } });
        } else {
            navigate('/payment-status', { state: { status: 'fail' } });
        }
    };

    // Display loading message while fetching data
    if (loading) {
        return (
          <>
          <div className="checkout-main sec_padding">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-8">
                <div className="progress-container step-second mb-5">
                    <div className="progress" id="progress"></div>
                    <div className="circle number_1 active check">
                        <div className="caption">Details</div>
                    </div>
                    <div className="circle number_2 active current_tab">
                        <div className="caption">Payment</div>
                    </div>
                    <div className="circle number_3">
                        <div className="caption">Completion</div>
                    </div>
                </div>
            </div>
          </div>
          <div className="row skeleton-container payment-detail skeleton-address">
            <div className="col-md-8">
                <div className="card mb-2">
                    <h5 className="card-header bg-white">
                      <div className="heading skeleton"></div>
                    </h5>
                    <div className="card-body">
                        <form id="form_paymentDetails" onSubmit={handleSubmit}>
                            {/* Cardholder Name */}
                            <div className="row gy-3 mb-3">
                                <div className="col-md-6">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                </div>
                                <div className="col-md-6 d-none d-md-block">
                                    
                                </div>

                                {/* Stripe CardElement for card input */}
                                <div className=" col-md-6">
                                    <label className="form-label skeleton"></label>
                                    <div className="input skeleton"></div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label"></label>
                                    <div className="input cv skeleton"></div>
                                </div>
                            </div>

                            {/* Submit Payment Form */}
                            <div className="row">
                                <div className="col-6">
                                  <div className="btn skeleton"></div>
                                </div>
                                <div className="col-6 text-end">
                                  <div className="btn skeleton"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <OrderSummary/>
        </div>
        </div>
      </div>
          </>
        );
      }

    return (
        <>
            <div className="checkout-main sec_padding">
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-md-8">
                            <div className="progress-container step-second mb-5">
                                <div className="progress" id="progress"></div>
                                <div className="circle number_1 active check">
                                    <div className="caption">Details</div>
                                </div>
                                <div className="circle number_2 active current_tab">
                                    <div className="caption">Payment</div>
                                </div>
                                <div className="circle number_3">
                                    <div className="caption">Completion</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card mb-2">
                                <h5 className="card-header d-flex justify-content-between align-items-center">
                                    <span>Payment Details</span>
                                </h5>
                                <div className="card-body">
                                    <form id="form_paymentDetails" onSubmit={handleSubmit}>
                                        {/* Cardholder Name */}
                                        <div className="row gy-3 mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="cardholderName">Cardholder Name</label>
                                                <div className="form_input">
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Cardholder Name"
                                                            id="cardholderName"
                                                            name="cardholderName"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Stripe CardElement for card input */}
                                            <div className="col-md-12">
                                                <label htmlFor="cardElement">Card Details</label>
                                                <div className="form_input">
                                                    <CardElement id="cardElement" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Submit Payment Form */}
                                        <div className="row">
                                            <div className="col-6">
                                                <Link to="/checkout" className="btn theme_btn">
                                                    Back
                                                </Link>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    className="btn theme_btn"
                                                    value="Pay Now"
                                                    type="submit"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                       <OrderSummary/>
                    </div>
                </div>
            </div>
        </>
    );
};

const PaymentDetailsWithStripe = () => (
    <Elements stripe={stripePromise}>
        <PaymentDetails />
    </Elements>
);

export default PaymentDetailsWithStripe;
