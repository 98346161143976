import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";


const BASE_URL = process.env.REACT_APP_APP_URL; // Laravel backend URL

const OrderSummary = () => {
    // To navigate after payment is processed
    const [serviceDetails, setServiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // To navigate after payment is processed
    const location = useLocation(); // Get the current location
    const encryptedKey = localStorage.getItem('encryptedKey');
    const authToken = localStorage.getItem('authToken'); // Retrieve the auth token from localStorage

    // Fetch service details
    useEffect(() => {
        const API_URL = encryptedKey
            ? `${BASE_URL}/api/payment/${encryptedKey}`
            : `${BASE_URL}/api/payment`;

        const fetchServiceDetails = async () => {
            try {
                const response = await fetch(API_URL, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });
                const data = await response.json();
                setServiceDetails(data);
                console.log("checkout",data);
            } catch (error) {
                console.error("Error fetching service details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceDetails();
    }, [encryptedKey, authToken]);

    if (loading) {
        return (
            <>
                <div className="col-md-4 skeleton-container order-summary skeleton-address">
                    <div className="card mb-2" data-aos="zoom-in-right">
                    <h5 className="card-header bg-white">
                        <div className="heading skeleton"></div>
                    </h5>
                        <div className="card-body">
                            <ul className="summry-content">
                                <li className="item skeleton"></li>
                                <li className="item skeleton"></li>
                                <li className="item skeleton"></li>
                            </ul>
                            {location.pathname === "/payment-status" && (
                    <Link to="/" className="btn theme_btn w-100">Go to Home</Link>
                )}
            

                        </div>
                    </div>
                </div>
            </>
        );
    }
   

    return (
        <>
            
         
            <div className="col-md-4">
                <div className="card mb-2" data-aos="zoom-in-right">
                    <h5 className="card-header d-flex justify-content-between align-items-center">
                        <span>Order Summary</span>
                    </h5>
                    <div className="card-body">
                        <ul className="summry-content">
                            <li className="item">
                                <span className="pera">
                                    Service name: {serviceDetails ? `${serviceDetails.service_name}` : "N/A"}
                                </span>
                            </li>
                            <li className="item">
                                <span className="pera">
                                    Service Description: {serviceDetails ? `${serviceDetails.service_description}` : "N/A"}
                                </span>
                            </li>
                            <li className="item">
                                <span className="pera fw-bold">
                                    Price: {serviceDetails ? `$${serviceDetails.price}` : "N/A"}
                                </span>
                            </li>
                        </ul>
                        {location.pathname === "/payment-status" && (
                <Link to="/" className="btn theme_btn w-100">Go to Home</Link>
            )}
            

                    </div>
                </div>
            </div>
                    
         
        </>
    );
};



export default OrderSummary;
