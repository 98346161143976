import React from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';

const Error404 = () => {
    AOS.init(
        {
            duration: 1200
          }
    ); // Initialize AOS animations
    return (
        <>

            {/* ---page-not-found---  */}

            <section className="sec_padding page-not-found d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h1 className="error text-center">404</h1>
                            <div className="page text-center">Ooops!!! The page you are looking for is not found</div>
                        <div className="text-center">
                            <Link className="btn theme_btn" to="/">Back to home</Link>
                        </div>
                        </div>
                        <img className="img-not-found d-none d-md-block" src="/assets/images/not-found.png" alt=""/>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Error404;
