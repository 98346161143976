import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams
import AOS from "aos";
import slugify from "slugify";
import { Helmet } from "react-helmet";

const ShopDetailPage = () => {
  const { categorySlug, productSlug } = useParams();
  const [productData, setProductData] = useState(null); // Store product data
  const [productPhotos, setProductPhotos] = useState([]);
  const [allProducts, setAllProducts] = useState([]); // Store all products data
  const [loading, setLoading] = useState(true); // Loading state
  const [quantity, setQuantity] = useState(1); // Quantity state

  useEffect(() => {
    AOS.init({ duration: 1200 });

    // Fetch the product details by SEO URL slug
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/topics/8/page/1/count/100`)
      .then((res) => res.json())
      .then((data) => {
        const product = data.topics.find((item) => item.seo_url_slug === productSlug);

        setProductData(product || null);
        setProductPhotos(product?.photos || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoading(false);
      });

    // Fetch all products (or a subset for related products)
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/topics/8/page/1/count/100`) // Adjust the endpoint if necessary
      .then((res) => res.json())
      .then((data) => {
        setAllProducts(data.topics); // Assuming 'topics' contains all products
      })
      .catch((error) => {
        console.error("Error fetching all products:", error);
      });
  }, [productSlug]); // Update dependency to slug instead of productId

  const handleQuantityChange = (type) => {
    setQuantity((prev) => (type === "increase" ? prev + 1 : Math.max(prev - 1, 1)));
  };

  const calculateTotalPrice = () => {
    return productData ? parseFloat(productData.fields[0].value.replace('$', '')) * quantity : 0;
  };

  // If the product data is not yet loaded, show loading state
  if (loading) {
    return (
      <>
        <section className="product-detail-container sec_padding skeleton-container">
          <div className="container">
            <div className="row">
              {/* Product Image and Sub-images */}
              <div className="col-sm-6">
                <div className="product-img-lg skeleton" data-aos="zoom-in"></div>
                <ul className="product-sub-imgs" data-aos="zoom-in">
                  <li className="img-sub skeleton"></li>
                  <li className="img-sub skeleton"></li>
                  <li className="img-sub skeleton"></li>
                  <li className="img-sub skeleton"></li>
                  <li className="img-sub skeleton"></li>
                </ul>
              </div>

              {/* Product Details */}
              <div className="col-sm-6 mt-3 mt-sm-0" data-aos="zoom-in-left">
                <div className="product-details">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="product-category skeleton"></div>
                    <div className="product-rating skeleton"></div>
                  </div>
                  <div className="prodcut-name skeleton"></div>

                  <div className="row">
                    <div className="col-sm-6">
                      {/* Quantity and Total Price */}
                      <div className="product-count skeleton"></div>

                      {/* Total Price */}
                      <p className="product-prize skeleton"></p>
                      <Link to="#" className="btn skeleton"></Link>
                    </div>
                    <div className="col-sm-6">
                      <div className="video-thumbnail skeleton"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  // Ensure the productData is available before rendering the product details
  if (!productData) {
    return <p>Product not found.</p>;
  }

  // Filter out the current product from the allProducts list for related products
  const relatedProducts = allProducts.filter((product) => product.seo_url_slug !== productData.seo_url_slug);

  return (
    <>
      <Helmet>
  <title>{productData.seo_title_en}- Shape Shifting Mastery </title> 
  <meta name="description" content={productData.seo_description_en} />
  <meta name="keywords" content={productData.seo_keywords || 'default, keywords'} />
</Helmet>
 

      {/* Product Detail Section */}
      <section className="product-detail-container sec_padding">
        <div className="container">
          <div className="row">
            {/* Product Image and Sub-images */}
            <div className="col-sm-6">
              <div className="product-img-lg" data-aos="zoom-in">
                <img
                  src={productData.photo_file || "assets/images/default-product.png"}
                  alt={productData.title}
                />
              </div>
              <ul className="product-sub-imgs" data-aos="zoom-in">
                {productPhotos.length > 0 ? (
                  productPhotos.map((photo, index) => (
                    <li key={photo.id || index} className="img-sub">
                      <img
                        src={photo.url || "assets/images/default-product.png"} // Fallback image
                        alt={`Sub-image ${index + 1}`}
                      />
                    </li>
                  ))
                ) : (
                  <li className="img-sub">
                    <img
                      src="assets/images/default-product.png"
                      alt="Default Sub-image"
                    />
                  </li>
                )}
              </ul>
            </div>

            {/* Product Details */}
            <div className="col-sm-6 mt-3 mt-sm-0" data-aos="zoom-in-left">
              <div className="product-details">
                <div className="d-flex justify-content-between flex-wrap">
                  <h4 className="product-category">{productData.Joined_categories[0]?.title}</h4>
                  <div className="product-rating">
                    {Array(5)
                      .fill()
                      .map((_, i) => {
                        const ratingValue = parseInt(productData.fields.find(field => field.title.toLowerCase() === "rating")?.value, 10);
                        return (
                          <i
                            key={i}
                            className={`fa-solid fa-star ${i < ratingValue ? "active" : "inactive"}`}
                          ></i>
                        );
                      })}
                    <span className="rating-number">({productData.fields.find(field => field.title.toLowerCase() === "rating")?.value})</span>
                  </div>
                </div>
                <h2 className="prodcut-name">{productData.title}</h2>

                <div className="row">
                  <div className="col-sm-6">
                    {/* Quantity and Total Price */}
                    <div className="product-count">
                      <span className="product-minus" onClick={() => handleQuantityChange("decrease")}>
                        <i className="fa fa-minus"></i>
                      </span>
                      <input
                        type="number"
                        name="product-count"
                        value={quantity}
                        className="product-count"
                        readOnly
                      />
                      <span className="product-plus" onClick={() => handleQuantityChange("increase")}>
                        <i className="fa fa-plus"></i>
                      </span>
                    </div>

                    {/* Total Price */}
                    <p className="product-prize">${calculateTotalPrice().toFixed(2)}</p>

                    <Link to="#" className="btn theme_btn">Shop Now</Link>
                  </div>
                  <div className="col-sm-6">
                    <div className="video-thumbnail">
                      <video controls="true" autoplay="" loop="" muted="muted" playsInline="" src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Product Description */}
            <div className="col-12 mt-3 mt-md-5" data-aos="zoom-in-up">
              <h2 className="sec_heading">Product Description</h2>
              <p
                className="pera product-desc"
                dangerouslySetInnerHTML={{ __html: productData.details }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <hr className="my-0" />
      </div>

      {/* Related Products Section */}
      <section className="related-products sec_padding">
        <div className="container">
          <div className="row g-2 g-sm-4">
            <div className="col-12" data-aos="fade-right">
              <h2 className="sec_heading">Related Products</h2>
            </div>

            {/* Check if related products are available */}
            {relatedProducts.length > 0 ? (
              relatedProducts.slice(0, 4).map((product) => {
                // Define slugs for the category and product
                const categorySlug = slugify(product.Joined_categories[0]?.title || "", { lower: true });
                const productSlug = product.seo_url_slug;

                return (
                  <div key={product.id} className="col-md-3 col-6" data-aos="zoom-in-up">
                    <Link
                      to={`/shop/${categorySlug}/${productSlug}`}
                      className="product-box"
                    >
                      <div className="product-img">
                        <img
                          src={product.photo_file || "assets/images/default-product.png"}
                          alt={product.title || "Product"}
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="product-title">{product.title}</h2>
                        <p className="product-rate">{product.fields?.[0]?.value || "$0.00"}</p>
                        <button className="shop-btn btn theme_btn">
                          Shop <span className="d-none d-sm-inline-block">Now</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <p>No related products available.</p>
            )}
          </div>
        </div>
      </section>

    </>
  );
};

export default ShopDetailPage;
