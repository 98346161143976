import axios from 'axios';


const token = localStorage.getItem('authToken');
const authUrl = process.env.REACT_APP_AUTH_URL;

const axiosInstance = axios.create({
  baseURL: `${authUrl}`, 
  
  // Your Laravel API base URL
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
    'Content-Type': 'application/json',
     Accept: 'application/json',
     
   
     
    
  },
});

export default axiosInstance;
