import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import slugify from "slugify";
import { Helmet } from "react-helmet";

const ShopPage = () => {
  const [uniqueCategories, setUniqueCategories] = useState([]); // Unique categories
  const [products, setProducts] = useState([]); // List of products
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered list based on categories & search
  const [selectedCategories, setSelectedCategories] = useState([]); // Selected categories for filtering
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [noResultsMessage, setNoResultsMessage] = useState(""); // No results message
  const [loading, setLoading] = useState(true); // Loading state, initially true to show loading
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const itemsPerPage = 6; // Items per page
  const baseUrl = process.env.REACT_APP_APP_URL;

  useEffect(() => {
    AOS.init({ duration: 1200 });

    setLoading(true); // Start loading
    fetch(`${baseUrl}/api/v1/topics/8/page/1/count/100`)
      .then((response) => response.json())
      .then((data) => {
        if (data?.topics) {
          setProducts(data.topics); // Set products from the API response
          setFilteredProducts(data.topics); // Initially set all products as filtered
          setTotalPages(Math.ceil(data.topics.length / itemsPerPage));
          // Extract unique categories
          const allCategories = data.topics.flatMap(
            (product) => product.Joined_categories
          );
          const uniqueCategoryTitles = [
            ...new Set(allCategories.map((cat) => cat.title)),
          ];
          setUniqueCategories(uniqueCategoryTitles); // Set unique categories
        }
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error("Error fetching categories and products:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, [baseUrl]);

  // Update filtered products based on selected categories and search query
  useEffect(() => {
    let updatedProducts = products;

    // Filter products by selected categories
    if (selectedCategories.length > 0) {
      updatedProducts = updatedProducts.filter((product) =>
        product.Joined_categories?.some((cat) =>
          selectedCategories.includes(cat.title)
        )
      );
    }

    // Filter by search query
    if (searchQuery) {
      updatedProducts = updatedProducts.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Set filtered products and no results message
    setFilteredProducts(updatedProducts);
    setTotalPages(Math.ceil(updatedProducts.length / itemsPerPage));
    setNoResultsMessage(
      updatedProducts.length
        ? ""
        : "No products found for the selected filters."
    );
    setCurrentPage(1);
  }, [selectedCategories, searchQuery, products]);

  // Handle category filter changes
  const handleCategoryChange = (categoryTitle) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryTitle)
        ? prev.filter((title) => title !== categoryTitle)
        : [...prev, categoryTitle]
    );
  };

  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return (
      <>

        <Helmet>
          <title>Our Products - Shape Shifting Mastery</title>
          <meta name="description" content={`Our Products`} />
          <meta
            name="keywords"
            content={
              "products, dandy blend, sugar free, lakanto,traditional medicine"
            }
          />
        </Helmet>

        {/* Product Listing Skeleton */}
        <section className="product-listing sec_padding skeleton-container">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <div className="col-md-3 border-end mb-3">
                <div className="row shop-sidebar" data-aos="fade-right">
                  <div className="col-12 mb-4">
                    <div className="sec_heading pb-2 mb-0 skeleton"></div>
                  </div>
                  <div className="col-12">
                    <form>
                      <ul className="product-category">
                        {Array.from({ length: 6 }).map((_, i) => (
                          <li key={i} className="category-item skeleton"></li>
                        ))}
                      </ul>
                    </form>
                  </div>
                </div>
              </div>

              {/* Product List */}
              <div className="col-md-9">
                <div className="d-flex pb-2 mb-4 gap-2 border-bottom justify-content-between align-items-center flex-wrap">
                  <div
                    className="sec_heading mb-0 skeleton"
                    data-aos="fade-right"
                  ></div>
                  <div
                    className="searchbar skeleton"
                    data-aos="fade-left"
                  ></div>
                </div>
                <div className="row g-1 g-sm-4" data-aos="fade-up">
                  {Array.from({ length: 6 }).map((_, i) => (
                    <div className="col-md-4 col-6" key={i}>
                      <div className="product-box">
                        <div className="product-img skeleton"></div>
                        <div className="product-content">
                          <div className="product-title skeleton"></div>
                          <div className="product-rating skeleton"></div>
                          <div className="product-price skeleton"></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    );
  }

  return (
    <>

      {/* Product Listing Section */}
      <section className="product-listing sec_padding">
        <div className="container">
          <div className="row">
            {/* Sidebar: Category Filter */}
            <div className="col-md-3 border-end mb-3">
              <div className="row shop-sidebar" data-aos="fade-right">
                <div className="col-12 mb-4">
                  <h2 className="sec_heading pb-2 mb-0 border-bottom">
                    Category <span>({uniqueCategories.length})</span>
                  </h2>
                </div>
                <div className="col-12">
                  <form>
                    <ul className="product-category">
                      {uniqueCategories.length > 0 ? (
                        uniqueCategories.map((categoryTitle) => (
                          <li key={categoryTitle} className="category-item">
                            <input
                              type="checkbox"
                              id={`category-${categoryTitle}`}
                              onChange={() =>
                                handleCategoryChange(categoryTitle)
                              }
                            />
                            <label htmlFor={`category-${categoryTitle}`}>
                              {categoryTitle}
                            </label>
                          </li>
                        ))
                      ) : (
                        <li>No categories available</li>
                      )}
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            {/* Product List */}
            <div className="col-md-9">
              <div className="d-flex pb-2 mb-4 gap-2 border-bottom justify-content-between align-items-center flex-wrap">
                <h2 className="sec_heading mb-0" data-aos="fade-right">
                  Product List <span>({filteredProducts.length})</span>
                </h2>

                {/* Search Bar */}
                <div className="searchbar" data-aos="fade-left">
                  <input
                    type="search"
                    className="search-input"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <div className="icon-submit">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              </div>

              {/* Product Display */}
              <div className="row g-2 g-sm-4" data-aos="fade-up">
                {paginatedProducts.length > 0 ? (
                  paginatedProducts.map((product) => {
                    const categorySlug = slugify(
                      product.Joined_categories[0]?.title,
                      { lower: true }
                    );
                    const productSlug = product.seo_url_slug;
                    const ratingField = product.fields.find(
                      (field) => field.title.toLowerCase() === "rating"
                    );
                    const rating = ratingField
                      ? parseInt(ratingField.value, 5)
                      : 0; // Default to 0 if no rating is found
                    return (
                      <div key={product.id} className="col-md-4 col-6">
                        <Link
                          to={`/shop/${categorySlug}/${productSlug}`} // Category and product slug in the URL
                          className="product-box"
                        >
                          <div className="product-img">
                            <img src={product.photo_file} alt={product.title} />
                          </div>
                          <div className="product-content">
                            <h2 className="product-title">{product.title}</h2>

                            {/* Rating Display */}
                            <div className="product-rating">
                              {[1, 2, 3, 4, 5].map((star) => {
                                const ratingValue = parseInt(
                                  product.fields.find(
                                    (field) =>
                                      field.title.toLowerCase() === "rating"
                                  )?.value,
                                  10
                                );
                                return (
                                  <i
                                    key={star}
                                    className={`fa-solid fa-star ${
                                      star <= ratingValue
                                        ? "active"
                                        : "inactive"
                                    }`}
                                  ></i>
                                );
                              })}
                            </div>

                            <div className="product-price">
                              {product.fields[0].value}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <p>{noResultsMessage}</p>
                )}
              </div>

              {/* Pagination */}
              <div className="pagination">
                <Link
                  to="#"
                  onClick={() => handlePageChange(currentPage - 1)}
                  className={`fa-solid icon pre ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <i className="fas fa-chevron-left"></i>
                </Link>
                <span className="current">{currentPage}</span>
                <Link
                  to="#"
                  onClick={() => handlePageChange(currentPage + 1)}
                  className={`fa-solid icon next ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <i className="fas fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default ShopPage;
