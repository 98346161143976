import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const Banner = () => {

    const [bannerData, setBannerData] = useState(null);
    const [videoData, setVideoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define the API URL using environment variable (APP_URL + API endpoint)
    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL = `${BASE_URL}/api/v1/topic/169`;
    const VIDEO_API_URL = `${BASE_URL}/api/v1/topic/182`;

    // Fetch data when the component is mounted
    useEffect(() => {
        AOS.init(
            {
                duration: 1200
            }
        ); // Initialize AOS animations

        const fetchBannerData = async () => {
            try {
                const response = await axios.get(API_URL);
                setBannerData(response.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        const fetchVideoData = async () => {
            try {
                const response = await axios.get(VIDEO_API_URL);
                setVideoData(response.data); // Store the video dataa
            } catch (err) {
                setError("Error fetching video data");
                console.error(err);
            }
        };

        fetchBannerData();
        fetchVideoData();
    }, [API_URL, VIDEO_API_URL]);



    // If loading, show a loading message
    if (loading) {
        return (
            // <section className="home-banner-section">
            //     <div className="container">
            //         <div className="row">
            //             <div className="col-12">
            //                 <p>Loading...</p>
            //             </div>
            //         </div>
            //     </div>
            // </section>

            <section className="home-banner-section skeleton-container">
                <div className="container">
                    <div className="row">
                        <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                            <div className="banner-caption">
                                <div>
                                    <h5 className="text-banner-badge hero skeleton"> </h5>
                                    <h1 className="main-heading hero mb-0 skeleton"> </h1>
                                </div>
                                <p className="pera hero skeleton">

                                </p>
                                <div className="d-flex gap-3 justify-content-between">
                                    <div className="btn-skeleton hero skeleton"></div>
                                    <div className="hero-btn-container d-flex gap-1">
                                        <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                            <div className="banner-img">
                                <div className="video hero  skeleton"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    // If there's an error, show an error message
    if (error) {
        return (
            <section className="home-banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    // If banner data is available, display it
    return (
        <section className="home-banner-section">
            <img rel="preload" src="assets/images/backgrond-home-new.png" className="background-hero" alt="Background" />
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge">{bannerData?.topic[0]?.fields?.find((field) => field.title === "Component-title")?.value ||
                                    "Title Not Found"}</h5>
                                <h1
                                    className="main-heading mb-0"
                                    dangerouslySetInnerHTML={{ __html: bannerData?.topic[0]?.details?.match(/<h1>(.*?)<\/h1>/)?.[1] || "Heading not found" }}
                                ></h1>

                            </div>
                            <p className="pera"
                             dangerouslySetInnerHTML={{ __html: bannerData?.topic[0]?.details?.match(/<p>(.*?)<\/p>/)?.[1] || 'Details not found'}}>

                                
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                                <Link to="https://masterhabits.com/" target="_blank" className="btn theme_btn">Take a Quiz Now</Link>
                                <Link to="/podcasts" className="podcast-btn">
                                    <div className="waves wave-1"></div>
                                    <div className="waves wave-2"></div>
                                    <div className="waves wave-3"></div>

                                    <span><i className="fa fa-play"></i></span> <p className="podcast-text-none">Free Podcast</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            {/* Display the video URL if available, else use a default video */}
                            <video controls="true" autoplay="" loop="" muted="muted" playsInline=""  src={videoData?.topic[0]?.video_file || "no video found"} />
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Banner;
