import React from "react";
import Podcast from "../components/Podcast";
import { Helmet } from "react-helmet";

const PodcastPage = () => {
    return (
        <>
        
            <Helmet>
                <title>Podcasts - Shape Shifting Mastery </title> {/* Replace "Your Blog Name" with your site's name */}
                <meta name="description" content={`Read more about our Podcasts`} />
            </Helmet>
            <Podcast />
        </>
    );
};

export default PodcastPage;
