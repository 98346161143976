import React, { useState } from "react";
import BlogSidebar from "../components/BlogSidebar";
import MyPhilosophy from "../components/MyPhilosophy";
import BlogComponent from "../components/BlogComponent";
import { CategoryProvider } from "../contexts/CategoryContext"; // Import the provider
import AOS from 'aos';
import { Helmet } from "react-helmet";

const BlogPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleCategorySelect = (categorySlug) => {
    setSelectedCategory(categorySlug);
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
  };


  AOS.init({ duration: 1200 }); // Initialize AOS animations

  return (
    <CategoryProvider> {/* Wrap the page with the CategoryProvider */}
      <Helmet>
  <title>Blogs - Shape Shifting Mastery</title> {/* Replace "Your Blog Name" with your site's name */}
  <meta name="description" content={`Read more about our Blogs.`} />
</Helmet>
      {/* MyPhilosophy Section */}
      <MyPhilosophy />

      {/* Blog List Section */}
      <section className="blog-list sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4" data-aos="fade-right">
              <h6 className="sub_heading">Blogs</h6>
              <h2 className="sec_heading-stylish">Together We Will</h2>
            </div>
            <div className="col-md-9">
              <div className="row">
                <BlogComponent selectedCategory={selectedCategory} searchQuery={searchQuery} />
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <BlogSidebar onCategorySelect={handleCategorySelect} onSearch={handleSearch}/>
            </div>
          </div>
        </div>
      </section>

    </CategoryProvider>
  );
};

export default BlogPage;
