import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import { Helmet } from "react-helmet";
import axiosInstance from "../Auth/axiosInstance";

const SuccessStoriesPage = () => {
  const [storyData, setStoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topics/28/page/1/count/10`; // Success stories endpoint

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });

    const fetchSuccessStoryData = async () => {
      try {
        const response = await axiosInstance.get(API_URL);
        console.log("Response:", response.data.topics); // Log the response for debugging purposes
        const topic = response.data.topics?.[0]; // Safely access the first item in the array
        console.log("Topic:", topic); // Log the topic for debugging purposes
        setStoryData(topic);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSuccessStoryData();
  }, [API_URL]); // Ensure dependencies are correct

  if (loading) {
    return (
      <>
        <section className="inner-banner-section success-stories about-top skeleton-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-12" data-aos="zoom-in-right">
                <div className="banner-caption pr-0">
                  <div>
                    <div className="text-banner-badge text-center skeleton"></div>
                    <div className="main-heading text-center mb-0 skeleton"></div>
                  </div>
                  <p className="pera text-center skeleton"></p>
                  <div className="d-flex gap-3 justify-content-center">
                    <div className="btn-skeleton hero skeleton"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  if (error) {
    return (
      <section>
        <p className="error-message">{error}</p>
      </section>
    );
  }
  const subHeading = storyData?.fields.find(
    (field) => field.title === "Sub-Heading"
  )?.value;
  const description = storyData?.fields.find(
    (field) => field.title === "Description"
  )?.value;

  const successstory = storyData?.fields.find(
    (field) => field.title === "Success Stories Title"
  )?.value;

  const successstorysubtitle = storyData?.fields.find(
    (field) => field.title === "Success Story sub-title"
  )?.value;

  const paraone = storyData?.fields.find(
    (field) => field.title === "Story Para One"
  )?.value;
  const paratwo = storyData?.fields.find(
    (field) => field.title === "Story Para Two"
  )?.value;
  const parathree = storyData?.fields.find(
    (field) => field.title === "Story Para Three"
  )?.value;

  const name = storyData?.fields.find(
    (field) => field.title === "Name"
  )?.value;
  const age = storyData?.fields.find(
    (field) => field.title === "Age"
  )?.value;




  return (
    <>
      <Helmet>
        <title>Success Stories - Shape Shifting Mastery</title>{" "}
        {/* Change the page title */}
        <meta
          name="description"
          content={`Read inspiring success stories from our community.`}
        />{" "}
        {/* Change meta description */}
      </Helmet>



      <section className="inner-banner-section about-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-12" data-aos="zoom-in-right">
              <div className="banner-caption pr-0">
                <div>
                  <h5 className="text-banner-badge text-center">
                    {storyData?.title || "Title"}
                  </h5>

                  <h1 className="main-heading text-center mb-0">
                    {subHeading || "TRANSFORMATIONS + TESTIMONIALS"}
                  </h1>
                </div>
                <p className="pera text-center">
                  
                  {description || "description"}
                </p>
                <div className="d-flex gap-3 justify-content-center">
                  <Link
                    to="https://masterhabits.com/"
                    target="_blank"
                    className="btn theme_btn"
                  >
                    Take a Quiz Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Stories List Section */}
      <section className="success-stories-list  sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4" data-aos="fade-right">
              <h6 className="sub_heading">{successstory || "Success Stories"}</h6>
              <h2 className="sec_heading-stylish">
                {successstorysubtitle || "Together We Will Achieve Success!"}
              </h2>{" "}
              {/* Change heading */}
            </div>
            <div className="col-md-12">
              <div className="row success-stories-item">
                <div className="col-lg-6" data-aos="fade-right">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/transform-four.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6" data-aos="fade-left">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      {paraone || "Para One"}<br />
                      <br />
                      {paratwo || "Para Two"}<br />
                      <br /> {parathree || "Para Three"}
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — {name}, {age} years old
                    </figcaption>
                  </figure>
                </div>
              </div>
              <div className="row success-stories-item">
                <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/transform-five.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6 order-1" data-aos="fade-right">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      Working with Michele has been a game-changer for me. At
                      over 35, I felt like I had tried everything when it came
                      to weight loss and wellness, but nothing seemed to stick.
                      Michele’s approach, however, is completely different. She
                      helped me shift my mindset, and now I feel more in control
                      of my body and health than ever before. <br />
                      <br />
                      Michele’s personalized coaching is not just about losing
                      weight—it’s about understanding and nurturing your body,
                      mind, and spirit. Her holistic methods have empowered me
                      to make healthier choices that I can sustain long-term,
                      and I’ve finally learned how to listen to my body. <br />
                      <br />
                      I used to feel frustrated and confused about why my
                      previous efforts hadn’t worked, but now I feel stronger,
                      healthier, and more confident. Michele doesn’t just teach
                      you how to transform physically, but how to feel truly
                      empowered in your journey. Thanks to her, I’ve found a
                      balance I didn’t even know was possible, and I’ve gained a
                      renewed sense of energy and purpose. <br />
                      <br />
                      I’m so thankful for Michele’s support and the lasting
                      changes I’ve made. I truly feel like I’ve invested in
                      myself, and I’d recommend her program to anyone who’s
                      ready for a lasting transformation.
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — Sarah, 38 years old
                    </figcaption>
                  </figure>
                </div>
              </div>
              <div className="row success-stories-item">
                <div className="col-lg-6" data-aos="fade-right">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/transform-six.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6" data-aos="fade-left">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      Working with Michele has been the best decision I’ve made
                      for my health. As a 45-year-old woman, I had struggled
                      with weight management for years, and I had tried
                      everything from extreme diets to intense workout
                      plans—none of which gave me long-term results. Michele’s
                      approach is completely different. She didn’t just focus on
                      the numbers on the scale; she helped me transform my
                      relationship with food and my body. <br />
                      <br />
                      What I appreciate most about Michele is how she takes a
                      holistic approach to health. She combines nutrition
                      education, fitness, and mental wellness, which helped me
                      understand my body’s needs in a way I never have before. I
                      now have the tools and knowledge to make healthier choices
                      that fit my lifestyle, and I’ve never felt more confident.{" "}
                      <br />
                      <br />
                      The biggest transformation, however, has been in my
                      mindset. I used to feel like I was constantly fighting
                      against my body, but now I feel empowered and at peace.
                      Michele’s guidance has taught me to nourish myself both
                      physically and mentally, and I’ve seen amazing
                      results—physically, I’m healthier, but mentally, I’m more
                      confident than ever. <br />
                      <br />I truly believe that Michele’s program is
                      life-changing. If you’re looking for a sustainable,
                      well-rounded approach to health, I would highly recommend
                      her services. Thank you, Michele, for helping me
                      rediscover a healthier, happier version of myself!
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — Janet, 45 years old
                    </figcaption>
                  </figure>
                </div>
              </div>
              <div className="row success-stories-item">
                <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/transform-seven.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6 order-1" data-aos="fade-right">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      I’ve struggled with my weight for years, and at 38, I
                      thought I had tried everything. Diets never worked for me,
                      and I felt like I was stuck in a cycle of starting over
                      again and again. But working with Michele was a complete
                      game changer. Her approach isn’t just about losing weight,
                      it’s about understanding the relationship you have with
                      your body and food. <br />
                      <br />
                      Michele’s coaching went beyond the physical aspect of
                      weight loss. She helped me tackle the mental barriers and
                      emotional patterns that were keeping me stuck. I’ve gained
                      so much more than just a slimmer body—I’ve gained a
                      healthier mindset and a newfound confidence that affects
                      every area of my life. <br />
                      <br />
                      I now understand how to nourish my body and mind, and I’ve
                      learned how to make healthy decisions that align with my
                      long-term goals. Michele’s program has helped me not only
                      lose weight but build the foundation for a life that’s
                      healthier, happier, and more balanced. <br />
                      <br />
                      If you’re looking for a real, lasting transformation that
                      goes beyond the scale, I highly recommend Michele’s
                      program. She truly helps you shift your mindset and your
                      life, and I’m so grateful for the changes I’ve made with
                      her guidance.
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — Karen, 38 years old
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Stories List Section */}
      <section
        id="bride-success-stories"
        className="success-stories-list bride sec_padding"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4" data-aos="fade-right">
              <h6 className="sub_heading">Bride Success Stories</h6>
              <h2 className="sec_heading-stylish">
                A Journey to the Perfect Day!
              </h2>
            </div>

            <div className="col-md-12">
              <div className="row success-stories-item">
                <div className="col-lg-6" data-aos="fade-right">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/bride-one.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6" data-aos="fade-left">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      I knew my wedding day was coming, and I wanted to feel my
                      best, but I had struggled with my weight for years and
                      wasn’t sure how to make the changes I wanted. Working with
                      Michele was a total game-changer. She didn’t just help me
                      lose weight, she gave me the tools to completely transform
                      how I approached health and wellness. <br />
                      <br />
                      Michele’s coaching helped me realize that this journey
                      wasn’t about perfection, but progress. I didn’t just lose
                      weight for my wedding day, I gained a new perspective on
                      nourishing my body, maintaining balance, and staying
                      confident in who I am. I felt strong and beautiful on my
                      big day, but the best part is that I now have the mindset
                      to maintain my results for the long term. <br />
                      <br />
                      The results speak for themselves, but it’s the emotional
                      and mental transformation that made this journey so
                      special. I’ll forever be grateful to Michele for helping
                      me feel my best, both inside and out. If you’re a
                      bride-to-be looking to transform your body and mindset, I
                      can’t recommend Michele enough!
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — Jessica, 30 years old
                    </figcaption>
                  </figure>
                </div>
              </div>

              <div className="row success-stories-item">
                <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/bride-two.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6 order-1" data-aos="fade-right">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      As a bride-to-be, I wanted to look and feel my best on my
                      wedding day, but I was struggling to lose those last few
                      pounds. Michele’s approach not only helped me achieve my
                      weight goals, but it also gave me a confidence I had never
                      felt before. <br />
                      <br />
                      What stood out to me the most was how Michele’s coaching
                      went beyond just the physical. She helped me address the
                      emotional and mental barriers that had been holding me
                      back from fully embracing a healthy lifestyle. With her
                      support, I made healthier choices without feeling
                      deprived, and I felt empowered to take control of my own
                      wellness. <br />
                      <br />
                      On my wedding day, I felt beautiful, confident, and ready
                      to start the next chapter of my life with a new sense of
                      self-love and self-care. Thanks to Michele, I not only
                      achieved my goals for my wedding day, but I’ve also
                      developed habits that will continue to serve me throughout
                      my marriage. If you’re a bride looking to feel confident
                      in your body, Michele is the coach for you!
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — Emily, 28 years old
                    </figcaption>
                  </figure>
                </div>
              </div>

              <div className="row success-stories-item">
                <div className="col-lg-6" data-aos="fade-right">
                  <div className="success-storie-img">
                    <img
                      src="assets/images/success-stories/bride-three.webp"
                      alt="bride-one"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-6" data-aos="fade-left">
                  <figure className="testimonial-qoute">
                    <blockquote className="pera">
                      <span className="quote-icon-top">“</span>
                      My wedding was approaching, and I wanted to look my best,
                      but I was feeling overwhelmed and unsure of where to
                      start. After working with Michele, I not only transformed
                      my body, but I also transformed my entire approach to
                      health and fitness. <br />
                      <br />
                      Michele’s approach is unique because it’s not about quick
                      fixes or drastic changes—it’s about sustainable, long-term
                      habits. Throughout the process, she helped me build
                      confidence, learn to love my body, and make choices that
                      supported my goals without feeling restricted. <br />
                      <br />
                      On my wedding day, I felt like the best version of
                      myself—strong, confident, and beautiful. The
                      transformation I experienced wasn’t just physical; it was
                      mental and emotional as well. I now have the tools to
                      maintain my results and continue living a healthy, happy
                      life. If you’re a bride-to-be looking to feel confident on
                      your big day, Michele is the coach you need!
                      <span className="quote-icon-bottom">”</span>
                    </blockquote>
                    <figcaption className="source text-end">
                      — Sarah, 34 years old
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default SuccessStoriesPage;
