import React from "react";
import Contact from "../components/Contact";
import { Link } from "react-router-dom";
import AOS from 'aos';
import { Helmet } from "react-helmet";

const ContactPage = () => {
    AOS.init(
        {
            duration: 1200
          }
    ); 
    return (
        <>
          <Helmet>
  <title>Contact Us - Shape Shifting Mastery</title> {/* Replace "Your Blog Name" with your site's name */}
  <meta name="description" content={`Contact Us .`} />
  <meta name="keywords" content={'shape , shape-shifting , contact'} />
</Helmet>
           
          <Contact />
            
        </>
    );
};

export default ContactPage;
