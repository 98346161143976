import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { Helmet } from "react-helmet";
import Testimonials from "../components/Testimonials";

const ServiceTwo = () => {

  return (
    <>
      <Helmet>
        <title>60 DAY Shape Shifting Mastery Transformation - Shape Shifting Mastery </title> 
        <meta name="description" content={`Our Services`} />
      </Helmet>


      <div className="transform-header">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="theme-box" data-aos="zoom-in">
                        <div className="theme-box-inner">
                            <div className="text-box centervertically">
                            <h5 className="Presents"><span className="stylish">Michele </span>Presents</h5>
                            <h3 className="title">60 DAY Shape Shifting Mastery<span className="stylish"> Transformation</span>   </h3>
                            <p className="pera">Transform your body and life with the revolutionary 60-Day Shape Shifting Mastery Program, a holistic approach to weight control and well-being. Combining personalized coaching, bio-hacking principles, and transformative self-care techniques along with a proprietary homeopathic drop that saves your muscle, this program offers rapid fat release of 1/2 to 2 lbs daily, empowering you to achieve lasting results and unlock your full potential.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <section className="down-arrow-container">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                <div className="height-40"> <span className="down-arrow d-flex flex-column justify-content-center">
                      <i className="fa-solid fa-angle-down"></i>
                      <i className="fa-solid fa-angle-down bottom-arrow"></i>
                      </span></div>
                  </div>
              </div>

          </div>
      </section>

      {/* ---session-start--- */}
        <section className="session service-features sec_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-right">
                <h1 className="main-heading">
                Health Coaching & Holistic Makeover Coaching 1:1 Session
                </h1>
               <p className="pera">Unlock your full potential with Holistic Health Coaching by Michele Drake. With personalized guidance and support, you'll embark on a transformative journey towards vibrant well-being. Our coaching offers:</p>

                <ul className="list-with-check">
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="pera">Personalized wellness plans tailored to your unique needs and goals.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="pera">Holistic approach addressing physical, mental, and emotional health.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="pera">Ongoing support and accountability to ensure long-term success.
                      </span>
                    </li>
                </ul>
              </div>
              <div className="col-lg-6  mt-lg-0 mt-3 mt-sm-4" data-aos="zoom-in-left">
                <div className="right-img drop-shadow-img">
                    <video controls="true" autoplay="" loop="" muted="muted" playsInline="" src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* ---session-end--- */}

      {/* ----service-detail-section--with prize--  */}


        <section className="product-detail-container sec_padding service-price-detail-section">
              <div className="container">
                <div className="row">
                  {/* Product Image and Sub-images */}
                  <div className="col-sm-6">
                    <div className="product-img-lg" data-aos="zoom-in">
                      <img
                        src="https://admin.shapeshiftingmastery.ca/uploads/topics/17361666148790.webp"
                        alt="service-img"
                      />
                    </div>
                  </div>
      
                  {/* Product Details */}
                  <div className="col-sm-6 mt-3 mt-sm-0" data-aos="zoom-in-left">
                    <div className="product-details">
                      <h2 className="prodcut-name">
                      60 DAY Shape Shifting Mastery <span className="stylish"> Transformation</span></h2>
                      <p className="pera">Transform your body and life with the revolutionary 60-Day Shape Shifting Mastery Program, a holistic approach to weight control and well-being. Combining personalized coaching, bio-hacking principles, and transformative self-care techniques along with a proprietary homeopathic drop that saves your muscle, this program offers rapid fat release of 1/2 to 2 lbs daily, empowering you to achieve lasting results and unlock your full potential.</p>
                      <h2>Payment Options :</h2>
                      {/* Total Price */}
                      <h2 className="product-prize">$1500 CND + HST * 2</h2>
      
                       <Link to="#" className="btn theme_btn">Buy Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

      {/* --testimonial-section-  */}
      <Testimonials/>

    </>
  );
};

export default ServiceTwo;
