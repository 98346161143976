import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import BlogSidebar from "../components/BlogSidebar";
import AOS from "aos";
import SocialLinks from "../components/SocialLinks";
import slugify from "slugify";
import { Helmet } from "react-helmet";
import Comment from "../components/Comment";

const BlogDetailPage = () => {
  const [blogData, setBlogData] = useState(null);
  const { category, seo_url_slug } = useParams(); // Use `seo_url_slug` instead of `id`

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });

    const fetchData = async () => {
      const BASE_URL = process.env.REACT_APP_APP_URL;
      const API_URL = `${BASE_URL}/api/v1/topics/7/page/1/count/4`; // Fetch the blog posts from this endpoint

      try {
        const response = await fetch(API_URL);
        const result = await response.json();

        // Find the matching blog based on the `seo_url_slug` from URL params
        const matchingBlog = result.topics.find(
          (blog) =>
            blog.seo_url_slug === seo_url_slug && // Match the seo_url_slug
            slugify(blog.Joined_categories[0]?.title || "uncategorized", { lower: true }) === category
        );

        if (matchingBlog) {
          setBlogData(matchingBlog);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchData();
  }, [category, seo_url_slug]); // Depend on `seo_url_slug` instead of `id`

  if (!blogData) {
    return (
      <>
        <section className="skeleton-container sec_padding">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-4" data-aos="zoom-in-up">
                <div className="sec_heading-stylish skeleton"></div>
                <p className="details skeleton"></p>
                <ul className="social skeleton"></ul>
              </div>
              <div className="col-md-12" data-aos="zoom-in-up">
                <div className="blog-feature-img skeleton"></div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{blogData.seo_title_en} - Shape Shifting Mastery</title> {/* Replace "Your Blog Name" with your site's name */}
        <meta name="description" content={`Read more about ${blogData.seo_description_en}.`} />
        <meta name="keywords" content={blogData.seo_keywords || 'default, keywords'} />
      </Helmet>


      {/* ---Single Blog Detail--- */}
      <section className="blog-detail-top sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4" data-aos="zoom-in-up">
              <h2 className="sec_heading-stylish text-center">{blogData.title}</h2>
              <p className="details text-center">
                by {blogData.user.name} | {new Date(blogData.date).toLocaleDateString()} | {blogData.Joined_categories[0].title}
              </p>
              <ul className="social">
                <SocialLinks />
              </ul>
            </div>
            <div className="col-md-12" data-aos="zoom-in-up">
              <div className="blog-feature-img">
                <img src={blogData.photo_file} alt={blogData.title} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---Blog Content--- */}
      <section className="blog-detail-container sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="blog-detail-content" data-aos="fade-up">
                <div dangerouslySetInnerHTML={{ __html: blogData.details }}></div>
              </div>

              {/* ---comment box--- */}

              {/* <Comment topicId={blogData.id} /> */}


              {/* comment box end */}

            </div>
            <div className="col-md-3 mt-3">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>

      {/* ---Related Blogs--- */}
    </>
  );
};

export default BlogDetailPage;
