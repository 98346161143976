import React, { useEffect } from "react";
import PaymentPublic from "../components/PaymentPublic";

import AOS from "aos";
import { Helmet } from "react-helmet";


const PaymentPublicLinkPage = () => {
    // Initialize AOS and scroll to top when the component mounts
    useEffect(() => {
        AOS.init({
            duration: 1200, // Animation duration
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
    
            <PaymentPublic />
        
        </>
    );
};

export default PaymentPublicLinkPage;
